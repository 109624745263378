<template>
  <a-modal
    title="订单退货"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="退货数量" has-feedback :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
        <a-input-number
          style="width: 100%"
          type="number"
          placeholder="请输入退货数量"
          v-decorator="['refundNum', { rules: [{ required: true, message: '请输入退货数量' }] }]"
          :min="1"
          :max="record.surplusNum"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { orderReturn } from '@/api/modular/mallLiving/orderAdmin'

export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      expressCompanyList: [], //快递公司列表
      form: this.$form.createForm(this),
      record: {},
    }
  },
  methods: {
    //初始化方法
    send(record) {
      console.log(record)
      this.visible = true
      this.record = record
    },
    // 确定按钮操作
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          if (String(values.refundNum).includes('.')) {
            return this.$message.info('请输入正确的退货数量！')
          }
          const { orderGoodsId, orderId } = this.record
          orderReturn({ id: orderGoodsId, orderId: orderId, ...values }).then((res) => {
            if (res.success) {
              this.$message.success('退货成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false //关闭确认按钮加载状态
      this.record = {}
      this.id = ''
      this.form.resetFields() //重置表单
    },
  },
}
</script>
